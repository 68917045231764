<template>
  <div class="px-8 mt-4">
    <v-dialog
      v-model="dialogRevertPPMP"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="ppmpModal"
      scrollable
    >
      <v-card>
        <v-toolbar max-height="65" v-if="ppmpData.PPMPID" dark color="green darken-2">
          <v-toolbar-title
            >{{ ppmpData.PPMPDescription }}
            <span v-if="ppmpData.PPMPTypeID == 1">(DBM)</span>
            <span v-if="ppmpData.PPMPTypeID == 2">(NON-DBM)</span>
            <span v-if="ppmpData.PPMPTypeID  == 3">(LIB)</span>
            </v-toolbar-title
          >
          <v-spacer></v-spacer>
          <span
            style="width: 300px"
            v-if="!(ppmpData.Amount > 0) && ppmpData.PPMPStatusID == 11"
          >
            Total <span class="hidden-sm-and-down">Balance </span>: ₱ {{ formatPrice(ppmpData.budgetAmount) }}<br />
            Available <span class="hidden-sm-and-down">Balance </span> : ₱
            {{
              formatPrice(
                parseFloat(ppmpData.budgetAmount) - parseFloat(ppmpUsedAmount)
              )
            }}
          </span>
          <span style="width: 300px" v-else>
            Total <span class="hidden-sm-and-down">Balance </span>: ₱ {{ formatPrice(ppmpData.Amount) }} <br />
            Available <span class="hidden-sm-and-down">Balance </span>: ₱
            {{
              formatPrice(
                parseFloat(ppmpData.Amount) - parseFloat(ppmpUsedAmount)
              )
            }}
          </span>
          <v-btn icon dark @click="clickCloseMainDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <!-----Stepper----->
        <div>

<!-- <v-stepper alt-labels>
            <v-stepper-header>
              <v-stepper-step
                color="green"
                step="1"
                class="pa-3 pl-md-16"
                :complete="prData.FinanceStatusID == 7"
              >
              <span class="text-no-wrap">Funds Availability</span>
              <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(prData.FinaceApprovedDate)}}</small>
              </v-stepper-step>

              <v-divider class="my-6"></v-divider>

              <v-stepper-step
                color="green"
                :complete="prData.PresidentStatusID == 7"
                step="2"
                class="pa-3 pr-md-16"
              >
                <span class="text-no-wrap">College President Approval</span>
                <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(prData.PresidentApprovedDate)}}</small>
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper> -->

          <v-stepper alt-labels>
            <v-stepper-header>
              <v-stepper-step
                color="green"
                step="1"
                :complete="ppmpData.RecommendingApprovalStatusID == 7"
                class="pa-3"
              >
                <span class="text-no-wrap">Recommending Approval</span>
                <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(ppmpData.RecommendingApprovalDate)}}</small>
              </v-stepper-step>

              <v-divider class="my-6"></v-divider>

              <v-stepper-step
                color="green"
                step="2"
                :complete="ppmpData.BudgetStatusID == 7"
                class="pa-3"
              >
              <span class="text-no-wrap">Budget Officer Approval</span>
              <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(ppmpData.BudgetApprovedDate)}}</small>
              </v-stepper-step>

              <v-divider class="my-6"></v-divider>

              <v-stepper-step
                color="green"
                step="3"
                :complete="ppmpData.FinanceStatusID == 7"
                class="pa-3"
              >
                <span class="text-no-wrap">Funds Availability</span>
                <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(ppmpData.FinanceApprovedDate)}}</small>
              </v-stepper-step>

              <v-divider class="my-6"></v-divider>

              <v-stepper-step
                color="green"
                :complete="ppmpData.PresidentStatusID == 7"
                step="4"
                class="pa-3"
              >
                <span class="text-no-wrap">College President Approval</span>
                <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(ppmpData.PresidentApprovedDate)}}</small>
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </div>

        <v-alert
          v-if="ppmpData.isPending"
          border="left"
          type="error"
          class="white--text amber accent-4"
          prominent
          dense
        >
          <div v-if="ppmpData.RecommendingApprovalRemarks"> <strong>Recommending Approval: </strong>{{ ppmpData.RecommendingApprovalRemarks }}</div>
          <div v-if="ppmpData.BudgetRemarks"> <strong>Budget Officer: </strong>{{ ppmpData.BudgetRemarks }}</div>
          <div v-if="ppmpData.FinanceRemarks"> <strong>Finance: </strong>{{ ppmpData.FinanceRemarks }}</div>
          <div v-if="ppmpData.PresidentRemarks"> <strong>College President: </strong>{{ ppmpData.PresidentRemarks }}</div>
        </v-alert>

        <!-----Header----->
        <v-card-text style="max-height: 2000px">
        <div>
          <v-row class="mt-4">
            <v-col cols="12" class="d-flex justify-center">
              <v-subheader class="font-weight-bold text-h6 text-center"
                >Project Procurement Management Plan (PPMP)</v-subheader
              >
            </v-col>
            <v-col cols="12" class="d-flex justify-center mt-n8">
              <v-subheader
                >{{ ppmpData.DateStart }} - {{ ppmpData.DateEnd }}</v-subheader
              >
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-5">
            <v-col cols="12" sm="6" md="2">
              <v-subheader class="font-weight-bold">Delivery Unit:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-subheader>{{
                ppmpDetails.DeliveryUnitDescription
              }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="2">
              <v-subheader class="font-weight-bold"
                >Office/ Committee:</v-subheader
              >
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-subheader>{{ ppmpDetails.OfficeDescription }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="2">
              <v-subheader class="font-weight-bold"
                >Program/ Activity/ Project (PAP) Title:</v-subheader
              >
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-subheader>{{ ppmpDetails.PurposeDescription }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="2">
              <v-subheader class="font-weight-bold">Fund Source:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-subheader>{{ ppmpDetails.FundCode }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters class="mb-5">
            <v-col cols="12" sm="6" md="2">
              <v-subheader class="font-weight-bold"
                >Specific Fund Source/ Fee:</v-subheader
              >
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-subheader>{{
                ppmpDetails.SpecificFundSourceDescription
              }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="2">
              <v-subheader class="font-weight-bold">Budget Code:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-subheader>{{ ppmpDetails.BudgetCode }}</v-subheader>
            </v-col>
          </v-row>
        </div>

        <v-row>
          <v-col lg="12" class="py-4">
            <v-card class="">
              <v-data-table
                :loading="loading"
                :headers="headers"
                :items="itemList"
                :options.sync="options"
                :server-items-length="totalCount"
                :footer-props="footerProps"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <span class="green--text text--darken-2"
                      >PPMP Item List</span
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      outlined
                      color="red darken-2"
                      dark
                      @click="print(ppmpData)"
                      class="mr-2"
                    >
                      <v-icon>print</v-icon>
                      Print
                    </v-btn>
                    <v-btn
                      outlined
                      color="red darken-2"
                      dark
                      v-if="ppmpData.parentPPMPID > 0 && ppmpData.PPMPTypeID == 2"
                      @click="printWithOldPPMP(ppmpData)"
                      class="mr-2"
                    >
                      <v-icon>print</v-icon>
                      Print  W/ Old PPMP
                    </v-btn>
                    <v-btn
                      outlined
                      color="red darken-2"
                      dark
                      v-if="ppmpData.parentPPMPID > 0 && ppmpData.PPMPTypeID == 3"
                      @click="printWithOldLIB(ppmpData)"
                      class="mr-2"
                    >
                      <v-icon>print</v-icon>
                      Print  W/ Old LIB
                    </v-btn>
                    <v-btn
                      color="red darken-2"
                      class="white--text"
                      v-if="ppmpData.PresidentStatusID != 7 && $store.state.user.roleID == 7"
                      @click="revert(ppmpData.BudgetID)"
                    >
                    <v-icon>mdi-undo-variant</v-icon>
                      revert
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost || 0) }}</span>
                </template>
                <template v-slot:[`item.Amount`]="{ item }">
                  <span class="text-no-wrap">₱ {{formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty))}}</span>
                </template>
                <template v-slot:[`item.Requested`]="{ item }">
                  <v-icon
                  small
                  v-if="item.PRID != 0"
                  class="mx-3"
                  color="green"
                  >mdi-check</v-icon>
                </template>
                <template v-slot:[`item.Revised`]="{ item }">
                  <v-icon
                  small
                  v-if="item.isRevised == 1"
                  class="mx-3"
                  color="green"
                  >mdi-check</v-icon>
                </template>
                <template v-slot:[`item.Utilized`]="{ item }">
                  <span class="text-no-wrap">₱ {{formatPrice(item.Utilized)}}</span>
                </template>
                <template v-slot:[`item.Obligation`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(getObligationAmount(item)) }}</span>
                </template>
                <template v-slot:[`item.Balance`]="{ item }">
                  <span class="text-no-wrap">₱ {{formatPrice((parseFloat((parseFloat(item.UnitCost) * parseFloat(item.Qty)).toFixed(2)) - parseFloat(parseFloat(getObligationAmount(item))).toFixed(2)) - parseFloat((parseFloat(item.Utilized)).toFixed(2)))}}</span>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogConfirmRevert" max-width="500px">
          <v-card>
            <v-card-title class="headline">Confirmation</v-card-title>
            <div class="mx-6">
              Are you sure you want to revert this revised PPMP/LIB?
              <br />
              <br />
              <i><b>Note:</b> Revised PPMP and LIB with the same budget code will be reverted simultaneously.</i>
            </div>
            <v-card-actions class="pt-5">
              <v-spacer></v-spacer>
              <v-btn color="grey darken-2" outlined @click="dialogConfirmRevert = false">Cancel</v-btn>
              <v-btn color="red darken-2 white--text" @click="confirmRevert()"><v-icon>mdi-undo-variant</v-icon>Revert</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>
    
    <script>
export default {

  props: {
    ppmpData: {},
  },
  data: () => ({
    ppmpDetails: [],
    itemsisLoading: false,
    items: [],
    search: null,
    ppmpUsedAmount: null,
    dialogRevertPPMP: false,
    headers: [
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "itemDescription", sortable: false },
      { text: "Quantity", value: "Qty", sortable: false, align: "center" },
      { text: "Unit Meas.", value: "UnitMeasureDescription", sortable: false, align: "center" },
      { text: "Unit Cost", value: "UnitCost", sortable: false, align: "end" },
      { text: "Amount", value: "Amount", sortable: false, align: "end" },
      { text: "Requested", value: "Requested", sortable: false, align: "center" },
      { text: "Revised", value: "Revised", sortable: false, align: "center" },
      { text: "Utilized", value: "Utilized", sortable: false, align: "end" },
      { text: "Obligation", value: "Obligation", sortable: false, align: "end" },
      { text: "Balance", value: "Balance", sortable: false, align: "end" }
    ],
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    loading: false,
    ppmp: [],
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    options: {},
    isLoading: false,
    itemList: [],
    loadingBtn: false,
    dialogConfirmRevert: false,
    obligations: [],
  }),
  watch: {
    search(val) {
      this.itemsisLoading = true;
      setTimeout(() => {
        if (val == this.search) {
          this.SearchItem();
        }
      }, 2000);
    },
    options: {
      handler() {
        if (this.totalCount > 0) {
          this.getItems();
        }
      },
      deep: true,
    },
    ppmpData: {
      handler(data) {
        // console.log(data);
        if (data.PPMPID) {
          this.deleteObligationList = [];
          this.dialogRevertPPMP = true;
          this.getItems();
        }
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    revert(BudgetID) {
      this.dialogConfirmRevert = true;
      this.toRevertBudgetID = BudgetID;
    },
    confirmRevert(){
      let data = new FormData();
      data.append("BudgetID", this.toRevertBudgetID);
      this.axiosCall("/ppmp/revised/revert", "POST", data).then((res) => {
        if(res.data.status) {
            this.toRevertBudgetID = null;
            this.dialogConfirmRevert = false;
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = 'success';
            this.fadeAwayMessage.message = 'PPMP successfully reverted!';
            this.fadeAwayMessage.header = 'System Message';
            this.dialogRevertPPMP = false;
            }else {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = 'error';
            this.fadeAwayMessage.message = res.data.message;
            this.fadeAwayMessage.header = 'System Message';
            }
      });
    },
    clickCloseMainDialog() {
      this.totalCount = 0;
      this.items = [];
      this.dialogRevertPPMP = false;
      this.eventHub.$emit("closeRevisedPPMPItemModal", false);
    },
    print(data) {
      if(data.PPMPTypeID == 3){
        window.open(
          this.apiUrl + "/ppmp/report/lib/" + data.PPMPID + "/" + data.BudgetID
        );
      }
      else{
        window.open(
          this.apiUrl + "/ppmp/report/" + data.PPMPID + "/" + data.BudgetID
        );
      }
    },
    printWithOldPPMP(data) {
      window.open(
        this.apiUrl + "/ppmp/report/" + data.PPMPID + "/" + data.BudgetID + "/revised"
      );
    },
    printWithOldLIB(data) {
      window.open(
        this.apiUrl + "/ppmp/report/lib/" + data.PPMPID + "/" + data.BudgetID + "/revised"
      );
    },
    initialize() {
      this.loading = true;
      this.axiosCall("/get/PPMP", "GET").then((res) => {
        this.loading = false;
        this.ppmp = res.data.data.result;
      });
    },
    getItems() {
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("BudgetID", this.ppmpData.BudgetID);
      data.append("PPMPID", this.ppmpData.PPMPID);
      data.append("isDBM", this.ppmpData.isDBM);
      this.axiosCall("/getPPMPItem", "POST", data).then((res) => {
        this.loading = false;
        this.itemList = res.data.data.result;
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
        this.ppmpUsedAmount = parseFloat((res.data.data.useAmount).toFixed(2));
        this.ppmpDetails = res.data.data.details;
        this.obligations = res.data.data.obligations;
        if(this.ppmpDetails.isRevised == 1){
        this.obligations.forEach(item => {
          this.ppmpUsedAmount = this.ppmpUsedAmount + parseFloat(parseFloat(item.Amount).toFixed(2));
        });
        }
      });
    },
    getObligationAmount(data){
      let total = 0;
        this.obligations.forEach(item => {
          if(item.PPMPItemID == data.ppmpItemID){
            total += parseFloat(item.Amount);
          }
        });
        return total;
    },
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.ppmpModal {
  overflow-x: hidden !important;
}
</style>